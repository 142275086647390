body {
    background-color: "#3B5954";
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: "MontserratMedium";
    src: local("MontserratMedium"),
        url("./fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
    font-weight: medium;
}

@font-face {
    font-family: "MontserratBold";
    src: local("MontserratBold"),
        url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "MontserratThin";
    src: local("MontserratThin"),
        url("./fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
    font-weight: thin;
}

* {
    margin: 0;
    padding: 0;
}

html,
body {
    overscroll-behavior: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
